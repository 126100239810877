import axios from 'axios'

export default class UserRepository {
  static list(queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/users', { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static listUserByRoleTitle(queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/users/list-user-by-role-title', { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static create(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/users', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static view(id) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/users/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static update(data) {
    return new Promise((resolve, reject) => {
      axios
        .put('/api/users/' + data.id + '/update-account', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static changePassword(data) {
    return new Promise((resolve, reject) => {
      axios
        .put('/api/users/' + data.id + '/change-password', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static delete(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/users/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static resendActivationEmail(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/users/' + data.id + '/resend-activation-email', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static createWhitelist(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/whitelist', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static updateWhitelist(data) {
    return new Promise((resolve, reject) => {
      axios
        .put('/api/whitelist/' + data.id, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static deleteWhitelist(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/whitelist/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}