import axios from 'axios'

export default class RoleRepository {
  static list(queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/roles', { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static create(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/roles', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static view(id) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/roles/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static delete(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/roles/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
  static update(data) {
    return new Promise((resolve, reject) => {
      axios
        .put('/api/roles/' + data.id, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}