import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import UserRepository from '@/modules/user/repository/userRepository'
import RoleRepository from '@/modules/user/repository/roleRepository'
import i18n from '@/libs/i18n'
import actionLoading from '@/mixins/actionLoading'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true, label: i18n.t('Modules.User.Label.Full name') },
    { key: 'email', sortable: true },
    { key: 'role', sortable: true, label: i18n.t('Modules.User.Label.Role') },
    { key: 'status', sortable: true, label: i18n.t('Status') },
    { key: 'actions', label: i18n.t('Actions') }
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('user')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const roleOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    UserRepository
      .list({
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        roleId: roleFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { users, meta, roles } = response.data.data
        callback(users)
        totalUsers.value = meta.total
        if(roleOptions.value.length == 0) {
          roleOptions.value = []
          roles.forEach((item, index) => {
            roleOptions.value.push({
              label: i18n.t(item.title),
              value: item.id
            })
          })
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    roleOptions,

    resolveUserStatusVariant,
    refetchData,


    roleFilter,
    statusFilter,
  }
}
